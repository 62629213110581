<template>
  <v-tooltip v-if="text" top :disabled="dontShowHoverText">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        x-small
        v-bind="attrs"
        v-on="on"
        v-clipboard="text"
        @success="textCopied"
        @error="textNotCopied"
      >
        <v-icon
          v-if="status == 'success'"
          :color="colorTheme.success"
          :small="!large"
          :large="large"
        >
          mdi-check-bold
        </v-icon>
        <v-icon
          v-else-if="status == 'error'"
          :color="colorTheme.error"
          :small="!large"
          :large="large"
        >
          mdi-alert-circle
        </v-icon>
        <v-icon v-else :small="!large" :large="large">
          mdi-clipboard-outline
        </v-icon>
      </v-btn>
    </template>
    <span> {{ textHover }} </span>
  </v-tooltip>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["text", "hoverText", "dontShowHoverText", "large"],
  data: () => ({
    status: "",
    delay: 3000,
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    textHover() {
      if (this.hoverText) {
        return this.hoverText;
      } else {
        return this.text;
      }
    },
  },
  methods: {
    textCopied() {
      this.status = "success";
      setTimeout(() => {
        this.status = "";
      }, this.delay);
    },
    textNotCopied() {
      this.status = "error";
      setTimeout(() => {
        this.status = "";
      }, this.delay);
    },
  },
};
</script>
