<template>
  <v-container fluid>
    <v-card>
      <v-card-title> Payouts </v-card-title>
      <v-data-table
        fixed-header
        class="scrollable-table__large"
        :headers="headers"
        :items="payouts"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalPayouts"
        item-key="id"
        :single-select="true"
        :footer-props="{
          'items-per-page-options': rowsPerPage,
        }"
      >
        <template v-slot:progress>
          <v-progress-linear
            :color="colorTheme.progress"
            indeterminate
          ></v-progress-linear>
        </template>
        <template v-slot:[`item.payAmount`]="{ item }">
          <cell-monetary-value :value="item.payAmount" />
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <cell-clipboard :text="item.id" />
        </template>
        <template v-slot:[`item.invoiceLineItemId`]="{ item }">
          <cell-clipboard :text="item.invoiceLineItemId" />
        </template>
      </v-data-table>
      <v-row class="ml-0 mr-0">
        <v-btn class="mb-2 ml-2" :color="colorTheme.button" dark @click="back">
          Back
        </v-btn>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { formatMonetaryValue } from "@/utils/format-util.js";

import CellClipboard from "@/components/common/CellClipboard.vue";
import CellMonetaryValue from "@/components/common/CellMonetaryValue.vue";

export default {
  emits: ["show-invoices"],
  components: {
    CellClipboard,
    CellMonetaryValue,
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: "Id",
        value: "id",
        sortable: false,
      },
      {
        text: "Invoice Item",
        value: "invoiceLineItemId",
        sortable: false,
      },
      {
        text: "Pay to Xtiva Code",
        value: "payToXtivaCode",
        sortable: false,
      },
      {
        text: "Pay Amount",
        value: "payAmount",
        align: "end",
        sortable: false,
      },
      {
        text: "Fee Type",
        value: "feeType",
        sortable: false,
      },
      {
        text: "Author",
        value: "author",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme", "rowsPerPage"]),
    ...mapState("payoutScreen", ["payouts", "totalPayouts", "pagination"]),
    ...mapState("invoiceScreen", ["selectedInvoices"]),
    options: {
      get() {
        return this.pagination;
      },
      set(newValue) {
        this.setPagination(newValue);
      },
    },
    page() {
      return this.options.page;
    },
    pageSize() {
      return this.options.itemsPerPage;
    },
  },
  watch: {
    page() {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      this.refresh();
    },
    pageSize(val) {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      this.refreshOrCut(val);
    },
  },
  methods: {
    ...mapMutations("payoutScreen", ["setPagination", "setPayouts"]),
    ...mapActions("payoutScreen", ["getPayouts"]),
    async refreshOrCut(pageSize) {
      let array = this.payouts;
      if (pageSize > array.length) {
        await this.refresh();
      } else {
        this.setPayouts(this.payouts.slice(0, pageSize));
      }
    },
    async refresh() {
      this.loading = true;
      await this.getPayouts({
        page: this.options.page,
        pageSize: this.options.itemsPerPage,
      });
      this.loading = false;
    },
    back() {
      this.$router.push({ name: "InvoiceScreen" });
    },
    formatMonetaryValue(number) {
      return formatMonetaryValue(number);
    },
  },
  async mounted() {
    if (this.selectedInvoices.length == 0) {
      this.$router.replace({ name: "InvoiceScreen" });
    } else {
      this.setPayouts([]);
      await this.refresh();
    }
  },
};
</script>
