var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Payouts ")]),_c('v-data-table',{staticClass:"scrollable-table__large",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.payouts,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalPayouts,"item-key":"id","single-select":true,"footer-props":{
        'items-per-page-options': _vm.rowsPerPage,
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":_vm.colorTheme.progress,"indeterminate":""}})]},proxy:true},{key:"item.payAmount",fn:function(ref){
      var item = ref.item;
return [_c('cell-monetary-value',{attrs:{"value":item.payAmount}})]}},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.id}})]}},{key:"item.invoiceLineItemId",fn:function(ref){
      var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.invoiceLineItemId}})]}}],null,true)}),_c('v-row',{staticClass:"ml-0 mr-0"},[_c('v-btn',{staticClass:"mb-2 ml-2",attrs:{"color":_vm.colorTheme.button,"dark":""},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }